import Link from 'next/link'
import { FC } from 'react'
import ArticleCard from '../ArticleCard'
import { BlogPost } from '@commerce/types/blog'
import { HoskingsButton } from '@components/ui'
import cn from 'clsx'

interface Props {
  title: string
  articles: BlogPost[]
  hideWrapper?: Boolean
}

const ArticlesGrid: FC<Props> = ({ title, articles, hideWrapper }) => {
  const buttonTitle = 'Explore the journal'

  return (
    <div className={cn("my-10 laptop:my-20", {wrapper: !hideWrapper})}>
      <div className="uppercase text-text-dark font-normal pb-6 laptop:pb-7 text-mobileh4 md:text-h4">
        {title}
      </div>
      <div className="flex flex-col tablet:flex-row justify-between gap-6 laptop:gap-8">
        {articles.map((article: BlogPost, index: number) => {
          return (
            <ArticleCard
              key={article.id}
              handle={article?.handle}
              title={article?.title}
              image={article?.image}
              excerpt={article?.excerpt}
            />
          )
        })}
      </div>
      <div className="pt-10 flex justify-center">
        <Link href={`/journal`}>
          <HoskingsButton variant="transparent" size="medium">
            {buttonTitle}
          </HoskingsButton>
        </Link>
      </div>
    </div>
  )
}

export default ArticlesGrid
