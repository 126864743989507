import React from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import Image from 'next/legacy/image'
import styles from './BannerBlock.module.css'
import Button from '@components/ui/HoskingsButton/Button'
import rightArrowIcon from '../../../site/public/icons/rightArrowIcon.svg'
import rightArrowDarkIcon from '../../../site/public/icons/rightArrowDarkIcon.svg'

export const LinkableWrapper = ({ applyLink = false, children, href }) => {
  return applyLink ? (
    <Link href={href} passHref legacyBehavior>
      <a>{children}</a>
    </Link>
  ) : (
    children
  )
}

const BannerBlock = ({ slice }) => {
  const {
    desktopImage,
    mobileImage,
    container,
    contentLayout,
    margin,
    contentSpacing,
    Heading,
    headingTag,
    headingType,
    ctaButtonStyle,
    ctaText,
    ctaUrl,
    textColor,
  } = slice.primary

  // Formatted layout name used for className
  const formattedLayoutName = contentLayout?.replace(/\s+/g, '-').toLowerCase()
  let contentBlockJustifyContent
  let bannerBlockMargin

  // When the content is center place the button in center or at the bottom.
  switch (contentSpacing) {
    case 'space between':
      contentBlockJustifyContent = 'space-between'
      break
    case 'center':
      contentBlockJustifyContent = 'start'
      break
    default:
      contentBlockJustifyContent = 'space-between'
  }

  // To have margin either on bottom of image or top & bottom.
  switch (margin) {
    case 'margin bottom':
      bannerBlockMargin = 'bannerBlockMarginBottomOnly'
      break

    case 'margin top and bottom':
      bannerBlockMargin = 'bannerBlockMarginBottomAndTop'
      break
    default:
      bannerBlockMargin = ''
  }

  const getHeadingTag = (headingType, headingTag) => {
    if (headingType && headingTag) {
      return 'h1'
    }

    if (!headingType && headingTag) {
      return 'h2'
    }

    return 'div'
  }

  const HeadingTag = getHeadingTag(headingType, headingTag)
  const applyLinkToAll = !!ctaUrl

  return (
    <>
      <div className={clsx(container === true && styles.wrapper)}>
        <div className={`${bannerBlockMargin} ${styles.bannerBlockContainer}`}>
          <LinkableWrapper applyLink={applyLinkToAll} href={ctaUrl}>
            {desktopImage.url && (
              <div className={styles.bannerBlockDesktopImage}>
                <Image
                  src={desktopImage.url}
                  width={desktopImage.dimensions.width}
                  height={desktopImage.dimensions.height}
                  alt="banner_desktop_image"
                  layout="responsive"
                  objectFit="cover"
                  unoptimized
                />
              </div>
            )}
            {mobileImage.url && (
              <div className={styles.bannerBlockMobileImage}>
                <Image
                  src={mobileImage.url}
                  width={mobileImage.dimensions.width}
                  height={mobileImage.dimensions.height}
                  alt="banner_desktop_image"
                  layout="responsive"
                  objectFit="cover"
                  unoptimized
                />
              </div>
            )}
            <div className={`contentBlock ${formattedLayoutName}`}>
              {formattedLayoutName === 'center' ? (
                <div className="contentCenterBlock">
                  <div className="emptyContentBlock" />
                  <div className="contentCenterBlockSpacing">
                    {Heading && (
                      <div>
                        <HeadingTag
                          className={clsx(
                            styles.heading,
                            textColor === 'Dark' && styles.darkTextColor
                          )}
                        >
                          {Heading}
                        </HeadingTag>
                      </div>
                    )}
                    <div className={clsx('ctaButton', formattedLayoutName)}>
                      {ctaText && ctaButtonStyle === 'outline' && (
                        <Button
                          variant="outlined"
                          size="medium"
                          className={clsx({
                            'text-dark-grey border-dark-grey':
                              textColor == 'Dark',
                          })}
                        >
                          {ctaText}
                        </Button>
                      )}
                      {ctaButtonStyle === 'simple text with arrow' && (
                        <span
                          className={clsx(
                            textColor === 'Dark' ? 'simpleBtnDark' : 'simpleBtn'
                          )}
                        >
                          <span
                            className={clsx(
                              textColor === 'Dark' && styles.darkTextColor
                            )}
                          >
                            {ctaText}
                          </span>
                          <span className="buttonArrow">
                            {textColor === 'Dark' ? (
                              <Image
                                src={rightArrowDarkIcon}
                                width={16}
                                height={14}
                                alt="right-arrow"
                              />
                            ) : (
                              <Image
                                src={rightArrowIcon}
                                width={16}
                                height={14}
                                alt="right-arrow"
                              />
                            )}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={clsx('content', formattedLayoutName)}>
                  {Heading && (
                    <div>
                      <HeadingTag
                        className={clsx(
                          styles.heading,
                          textColor === 'Dark' && styles.darkTextColor
                        )}
                      >
                        {Heading}
                      </HeadingTag>
                    </div>
                  )}
                  <div className={clsx('ctaButton', formattedLayoutName)}>
                    {ctaButtonStyle === 'outline' && (
                      <Button
                        variant="outlined"
                        size="medium"
                        className={clsx('outlinedButton', {
                          'text-dark-grey border-dark-grey':
                            textColor == 'Dark',
                        })}
                      >
                        {ctaText}
                      </Button>
                    )}
                    {ctaButtonStyle === 'simple text with arrow' && (
                      <span
                        className={clsx(
                          textColor === 'Dark' ? 'simpleBtnDark' : 'simpleBtn'
                        )}
                      >
                        <span
                          className={clsx(
                            textColor === 'Dark' && styles.darkTextColor
                          )}
                        >
                          {ctaText}
                        </span>
                        <span className="buttonArrow">
                          {textColor === 'Dark' ? (
                            <Image
                              src={rightArrowDarkIcon}
                              width={16}
                              height={14}
                              alt="right-arrow"
                            />
                          ) : (
                            <Image
                              src={rightArrowIcon}
                              width={16}
                              height={14}
                              alt="right-arrow"
                            />
                          )}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </LinkableWrapper>
        </div>
      </div>
      <style jsx>
        {`
          .contentBlock {
            box-sizing: border-box;
            position: absolute;
            height: 100%;
            width: 90%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
            display: flex;
          }
          .content {
            word-break: normal;
          }
          .contentCenterBlock {
            height: 100%;
          }
          .emptyContentBlock {
            height: 20%;
          }

           {
            /* content spacing between heading & button */
          }
          .contentCenterBlockSpacing {
            display: flex;
            flex-direction: column;
            height: 80%;
            justify-content: ${contentBlockJustifyContent};
          }

           {
            /* content layout left top position */
          }
          .left-top {
            text-align: left;
            align-items: flex-start;
          }

           {
            /* content layout left bottom position */
          }
          .left-bottom {
            text-align: left;
            align-items: flex-end;
          }

           {
            /* content layout left middle position */
          }
          .left-middle {
            align-items: center;
          }

           {
            /* content layout center position */
          }
          .center {
            align-items: center;
            justify-content: center;
            text-align: center;
          }

           {
            /* content layout right top position */
          }
          .right-top {
            justify-content: right;
            align-items: flex-start;
          }

           {
            /* content layout right middle position */
          }
          .right-middle {
            justify-content: right;
            align-items: center;
          }

           {
            /* content layout right bottom position */
          }
          .right-bottom {
            justify-content: right;
            align-items: flex-end;
          }

           {
            /* content layout center top position */
          }
          .center-top {
            text-align: center;
            justify-content: center;
          }

           {
            /* content layout center bottom position */
          }
          .center-bottom {
            text-align: center;
            justify-content: center;
            align-items: end;
          }
          .content.left-top,
          .content.right-top,
          .content.center-top {
            margin-top: 40px;
          }
          .content.left-bottom,
          .content.right-bottom,
          .content.center-bottom {
            margin-bottom: 40px;
          }
          .content.left-top,
          .content.left-middle,
          .content.left-bottom {
            ${container === true ? '' : 'margin-left: 20px'};
          }
          .content.right-top,
          .content.right-middle,
          .content.right-bottom {
            ${container === true ? '' : 'padding: 0 20px'};
          }
          .ctaButton.center,
          .ctaButton.center-top,
          .ctaButton.center-bottom {
            display: flex;
            justify-content: center;
          }
          .contentCenterBlock {
            padding: 0 20px 40px 20px;
          }
          .bannerBlockMarginBottomAndTop {
            margin: 40px 0;
          }
          .bannerBlockMarginBottomOnly {
            margin-bottom: 10px;
          }
          @media (min-width: 767px) {
            .contentBlock {
              max-width: 1440px;
              ${container === true ? `width: 90%` : `width: 100%`};
            }
            .emptyContentBlock {
              height: 45%;
            }
            .contentCenterBlockSpacing {
              display: flex;
              flex-direction: column;
              height: 55%;
              justify-content: ${contentBlockJustifyContent};
            }
          }
          @media (min-width: 1024px) {
            .contentBlock {
              max-width: 1440px;
              ${container === true ? `padding: 0` : `padding: 0 4rem 0 4rem`};
            }
            .bannerBlockMarginBottomOnly {
              margin-bottom: 40px;
            }
            .bannerBlockMarginBottomAndTop {
              margin: 80px 0;
            }
            .content.left-top,
            .content.left-middle,
            .content.left-bottom {
              margin-left: 0;
            }
            .content.right-top,
            .content.right-middle,
            .content.right-bottom {
              padding: 0;
            }
            .contentCenterBlock {
              padding: 0 0 40px 0;
            }
          }
        `}
      </style>
      <style jsx global>
        {`
          .buttonArrow {
            padding-left: 5px !important;
            padding-top: 3px;
          }
          .simpleBtn,
          .simpleBtnDark {
            font-size: 16px;
            color: #fff;
            display: flex;
            align-items: center;
          }
          .simpleBtn:hover {
            text-decoration: underline;
            text-underline-position: under;
          }
          .simpleBtnDark:hover {
            text-decoration: underline;
            text-underline-position: under;
            color: #474747;
          }
        `}
      </style>
    </>
  )
}

export default BannerBlock
