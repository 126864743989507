import React from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import Image from 'next/legacy/image'
import styles from './Banner.module.css'
import Button from '@components/ui/HoskingsButton/Button'
import rightArrowIcon from '../../public/icons/rightArrowIcon.svg'
import rightArrowDarkIcon from '../../public/icons/rightArrowDarkIcon.svg'

export const LinkableWrapper = ({ applyLink = false, children, href }) => {
  return applyLink ? (
    <Link href={href} passHref legacyBehavior>
      <a>{children}</a>
    </Link>
  ) : (
    children
  )
}

const Banner = ({ slice }) => {
  const { variation } = slice;
  const {
    desktopImage,
    mobileImage,
    container,
    contentLayout,
    margin,
    contentSpacing,
    heading,
    headingType,
    ctaButtonStyle,
    ctaText,
    ctaUrl,
    textColor,
  } = slice.primary

  // Formatted layout name used for className
  const formattedContentLayout = contentLayout?.replace(/\s+/g, '-').toLowerCase()
  let formattedContentSpacing;
  let formattedMargin;

  // When the content is center place the button in center or at the bottom.
  switch (contentSpacing) {
    case 'Space Between':
      formattedContentSpacing = 'space-between';
      break;
    default:
      formattedContentSpacing = 'start';
  }

  // To have margin either on bottom of image or top & bottom.
  switch (margin) {
    case 'Margin Bottom':
      formattedMargin = 'margin-bottom'
      break;
    case 'Margin Top and Bottom':
      formattedMargin = 'margin-top-bottom'
      break;
    default:
      formattedMargin = ''
  }

  const getHeadingTag = (headingType) => {
    switch (headingType) {
        case 'H1':
            return 'h1'
        case 'H2':
            return 'h2'
        default:
            return 'div'
    }
  }

  const HeadingTag = getHeadingTag(headingType)
  const applyLinkToAll = !!ctaUrl

  return (
    <div className={clsx(container === true && styles.wrapper, styles[variation])}>
        <div className={`${styles[formattedMargin]} ${styles.bannerContainer}`}>
            <LinkableWrapper applyLink={applyLinkToAll} href={ctaUrl}>
                {desktopImage && desktopImage.url && (
                    <div className={styles.bannerDesktopImage}>
                    <Image
                        src={desktopImage.url}
                        width={desktopImage.dimensions.width}
                        height={desktopImage.dimensions.height}
                        alt="banner_desktop_image"
                        layout="responsive"
                        objectFit="cover"
                        unoptimized
                    />
                    </div>
                )}
                {mobileImage && mobileImage.url && (
                    <div className={styles.bannerMobileImage}>
                    <Image
                        src={mobileImage.url}
                        width={mobileImage.dimensions.width}
                        height={mobileImage.dimensions.height}
                        alt="banner_mobile_image"
                        layout="responsive"
                        objectFit="cover"
                        unoptimized
                    />
                    </div>
                )}
                <div className={`${styles.contentBlock} ${styles[formattedContentLayout]} ${styles[formattedContentSpacing]}`}> 
                    {heading && (
                        <div>
                        <HeadingTag
                            className={clsx(
                            styles.heading,
                            textColor === 'Dark' && styles.darkTextColor
                            )}
                        >
                            {heading}
                        </HeadingTag>
                        </div>
                    )}
                    <div className={clsx('ctaButton', formattedContentLayout)}>
                        {ctaText && ctaButtonStyle === 'outline' && (
                        <Button
                            variant="outlined"
                            size="medium"
                            className={clsx({
                            'text-dark-grey border-dark-grey':
                                textColor == 'Dark',
                            })}
                        >
                            {ctaText}
                        </Button>
                        )}
                        {ctaButtonStyle === 'simple text with arrow' && (
                        <span
                            className={clsx(
                            textColor === 'Dark' ? 'simpleBtnDark' : 'simpleBtn'
                            )}
                        >
                            <span
                            className={clsx(
                                textColor === 'Dark' && styles.darkTextColor
                            )}
                            >
                            {ctaText}
                            </span>
                            <span className="buttonArrow">
                            {textColor === 'Dark' ? (
                                <Image
                                src={rightArrowDarkIcon}
                                width={16}
                                height={14}
                                alt="right-arrow"
                                />
                            ) : (
                                <Image
                                src={rightArrowIcon}
                                width={16}
                                height={14}
                                alt="right-arrow"
                                />
                            )}
                            </span>
                        </span>
                        )}
                    </div>
                </div>
            </LinkableWrapper>
        </div>
    </div>
  )
}

export default Banner
