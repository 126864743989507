import React, { useState, useEffect } from 'react'
import { PrismicRichText } from '@prismicio/react'
import styles from './Modal.module.css'

const PopUp = ({ onRequestClose, slice }) => {
  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        onRequestClose()
      }
    }

    // Prevent scolling
    document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', onKeyDown)

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = 'visible'
      document.removeEventListener('keydown', onKeyDown)
    }
  })

  return (
    <div className={styles.modal__backdrop}>
      <div className={styles.modal__container}>
        <div className={styles.modal__body}>
          <div className={styles.modal__heading}>
            {slice.primary.modalHeading && (
              <PrismicRichText field={slice.primary.modalHeading} />
            )}
          </div>
          <div className={styles.modal__text}>
            {slice.primary.modalText && (
              <PrismicRichText field={slice.primary.modalText} />
            )}
          </div>
          <img
            onClick={onRequestClose}
            className={styles.modal__closeBtn}
            src="/icons/modalCloseBtn.png"
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

const Modal = ({ slice }) => {
  const [isModalOpen, setModalIsOpen] = useState(false)
  const toggleModal = () => {
    setModalIsOpen(!isModalOpen)
  }

  return (
    <>
      <div className={`${slice.primary.container && styles.wrapper}`}>
        {isModalOpen && <PopUp onRequestClose={toggleModal} slice={slice} />}

        <div className={styles.modalBlock}>
          <div className={styles.heading}>
            {slice.primary.heading && (
              <PrismicRichText field={slice.primary.heading} />
            )}
          </div>
          <div className={styles.text}>
            {slice.primary.text && (
              <PrismicRichText field={slice.primary.text} />
            )}{' '}
            <span
              className={styles.openModalBtn}
              onClick={toggleModal}
              type="button"
            >
              {slice.primary.buttonText}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal
