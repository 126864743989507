import React from 'react'
import Image from "next/legacy/image"
import styles from './ImagesBlock.module.css'

const ImagesBlock = ({ slice }) => (
  <div className={`${slice.primary.container && styles.wrapper}`}>
    <div className={styles.imagesBlock}>
      {slice?.items?.map((item, i) => {
        const { url, dimensions } = item.image
        const { width, height } = dimensions
        return (
          <div className={styles.imgItem} key={i}>
            <Image
              src={url}
              width={width}
              height={height}
              className={styles.img}
              unoptimized
            />
          </div>
        )
      })}
    </div>
  </div>
)

export default ImagesBlock
