import React from 'react'
import clsx from 'clsx'
import Image from "next/legacy/image"
import Link from 'next/link'
import { PrismicRichText } from '@prismicio/react'
import Slider from 'react-slick'
import styles from './BrandsBlock.module.css'
import { HoskingsButton } from '@components/ui'

const BrandsBlock = ({ slice }) => {
  const { container, heading, buttonText, buttonUrl } = slice.primary
  const brandItems = slice.items

  const settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className={clsx(container && styles.wrapper)}>
      <div className={styles.brandsBlock}>
        <h2 className={styles.heading}> {heading}</h2>
        {/* carousel */}
        <div className="brandsCarousel">
          <Slider {...settings}>
            {brandItems.map((item, i) => (
              <Link href={item.brandLink ? item.brandLink : '#'} key={i} legacyBehavior>
                <div className={styles.grid__item} key={i}>
                  <Image
                    src={item.brandLogo.url}
                    alt={item.brandLogo.alt || heading}
                    width={item.brandLogo.dimensions.width}
                    height={item.brandLogo.dimensions.height}
                    unoptimized
                  />
                  <div className={styles.grid__itemTitle}>
                    {item.title && <PrismicRichText field={item.title} />}
                  </div>
                </div>
              </Link>
            ))}
          </Slider>
        </div>
        <div className="pt-12 flex justify-center">
          <Link href={buttonUrl}>

            <HoskingsButton variant="transparent" size="medium">
              {buttonText}
            </HoskingsButton>

          </Link>
        </div>
      </div>
    </div>
  );
}

export default BrandsBlock
