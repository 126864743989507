// This file is generated by Slice Machine. Update with care!

import Banner from './Banner';
import BannerBlock from './BannerBlock';
import BlogBlock from './BlogBlock';
import BrandList from './BrandList';
import BrandPromoOff from './BrandPromoOff';
import BrandsBlock from './BrandsBlock';
import Code from './Code';
import CollectionProductsGrid from './CollectionProductsGrid';
import Content from './Content';
import DownloadBlock from './DownloadBlock';
import Faq from './Faq';
import FaqBlock from './FaqBlock';
import GiftwithPurchase from './GiftwithPurchase';
import Grid from './Grid';
import HeroBlockSlice from './HeroBlockSlice';
import HomepageCollectionsGrid from './HomepageCollectionsGrid';
import Iframe from './Iframe';
import ImagesBlock from './ImagesBlock';
import InstagramBlock from './InstagramBlock';
import MegaNavCallout from './MegaNavCallout';
import MegaNavColumn from './MegaNavColumn';
import Modal from './Modal';
import NavBlock from './NavBlock';
import NavItem from './NavItem';
import ProductInfoBlocks from './ProductInfoBlocks';
import ProductInfoListing from './ProductInfoListing';
import ReadMoreBlock from './ReadMoreBlock';
import ServiceBlock from './ServiceBlock';
import SizeChartTable from './SizeChartTable';
import TabBlock from './TabBlock';
import Table from './Table';
import TagPromoOff from './TagPromoOff';
import TestSlice from './TestSlice';
import TextBlock from './TextBlock';
import TextTable from './TextTable';
import VideoBlock from './VideoBlock';
import WaterResistanceTable from './WaterResistanceTable';

export {
	Banner,
	BannerBlock,
	BlogBlock,
	BrandList,
	BrandPromoOff,
	BrandsBlock,
	Code,
	CollectionProductsGrid,
	Content,
	DownloadBlock,
	Faq,
	FaqBlock,
	GiftwithPurchase,
	Grid,
	HeroBlockSlice,
	HomepageCollectionsGrid,
	Iframe,
	ImagesBlock,
	InstagramBlock,
	MegaNavCallout,
	MegaNavColumn,
	Modal,
	NavBlock,
	NavItem,
	ProductInfoBlocks,
	ProductInfoListing,
	ReadMoreBlock,
	ServiceBlock,
	SizeChartTable,
	TabBlock,
	Table,
	TagPromoOff,
	TestSlice,
	TextBlock,
	TextTable,
	VideoBlock,
	WaterResistanceTable,
};

export const components = {
	banner: Banner,
	banner_block: BannerBlock,
	blog_block: BlogBlock,
	brand_list: BrandList,
	brand_promo_off: BrandPromoOff,
	brands_block: BrandsBlock,
	code: Code,
	collection_products_grid: CollectionProductsGrid,
	content: Content,
	download_block: DownloadBlock,
	faq: Faq,
	faq_block: FaqBlock,
	giftwith_purchase: GiftwithPurchase,
	grid: Grid,
	hero_block_slice: HeroBlockSlice,
	homepage_collections_grid: HomepageCollectionsGrid,
	iframe: Iframe,
	images_block: ImagesBlock,
	instagram_block: InstagramBlock,
	mega_nav_callout: MegaNavCallout,
	mega_nav_column: MegaNavColumn,
	modal: Modal,
	nav_block: NavBlock,
	nav_item: NavItem,
	product_info_blocks: ProductInfoBlocks,
	product_info_listing: ProductInfoListing,
	read_more_block: ReadMoreBlock,
	service_block: ServiceBlock,
	size_chart_table: SizeChartTable,
	tab_block: TabBlock,
	table: Table,
	tag_promo_off: TagPromoOff,
	test_slice: TestSlice,
	text_block: TextBlock,
	text_table: TextTable,
	video_block: VideoBlock,
	water_resistance_table: WaterResistanceTable,
};
