import WYSIWYG from '@components/ui/WYSIWYG'

interface Props {
  slice: any
}

const Content = ({ slice }: Props) => {
  return (
    <section className="content">
      {slice.primary.content && <WYSIWYG content={slice.primary.content} />}
      <style jsx global>{`
        .content h3 {
          font-size: 1.5rem;
          line-height: 2.5rem;
          --tw-text-opacity: 1;
          color: rgb(100 100 100 / var(--tw-text-opacity));
          // padding-bottom: 10px;
          padding: 0.75rem 0rem;
          font-weight: bold;
        }
        .content h4 {
          font-size: 1.5rem;
          line-height: 2rem;
          --tw-text-opacity: 1;
          color: rgb(100 100 100 / var(--tw-text-opacity));
          padding: 0.75rem 0rem;
          font-weight: bold;
        }
        .content p strong {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 700;
          --tw-text-opacity: 1;
          color: rgb(100 100 100 / var(--tw-text-opacity));
          // padding-bottom: 0.75rem;
          // padding-top: 1.5rem;
          padding: 0.75rem 0rem;
        }
        .content p {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 300;
          // padding-top: 1rem;
          padding: 0.75rem 0rem;
        }
        .content a {
          text-decoration: underline;
        }
        .content li {
          list-style-type: disc;
          list-style-position: inside;
          font-weight: 300;
        }
        @media (max-width: 1024px) {
          .content h3 {
            font-size: 1.625rem;
            line-height: 2rem;
            padding: 0.75rem 0rem;
          }
          .content h4 {
            font-size: 1.25rem;
            line-height: 1.625rem;
            padding: 0.75rem 0rem;
          }
        }
      `}</style>
    </section>
  )
}

export default Content
