import { ProductCard } from '@components/product'
import { FC } from 'react'

interface Props {
  hit: any
  isSoldOut?: boolean
}

const AlgoliaHit: FC<Props> = ({ hit, isSoldOut }) => {
  const images = [{ url: hit.image }]

  const brandTags = hit?.named_tags?.Brand
  const brand = typeof brandTags === 'string' ? brandTags : brandTags?.[0]

  return (
    <ProductCard
      id={hit.objectID}
      price={hit.price}
      compareAtPrice={hit.compare_at_price}
      key={hit.id}
      name={hit?.title}
      namedTags={hit?.named_tags}
      images={images}
      link={`/${hit.handle}`}
      brand={brand?.replace(/_/g, ' ')}
      isSoldOut={isSoldOut}
      tags={hit?.tags}
    />
  )
}

export default AlgoliaHit
