import Link from 'next/link'

const BrandList = ({ slice }) => {
  const { items } = slice
  if (!items || !items.length) return null

  const group = items.reduce((result, item) => {
    if (item.brandName) {
      const firstLetter = item.brandName.substring(0, 1)
      result[firstLetter] = [...(result[firstLetter] || []), item]
    }

    return result
  }, {})

  const brandFirstLetters = Object.keys(group).sort()
  const brandsArray = Object.entries(group).sort()

  return (
    <div className="wrapper">
      <div className="brand-list-block">
        <div className="brand-list-block-letters">
          {brandFirstLetters &&
            brandFirstLetters.map((letter, index) => (
              <div className="brand-list-letter" key={`brand_letters_${index}`}>
                <a href={`#brand-${letter}`}>
                  <span className="brand-list-letter-value" key={letter}>
                    {letter}
                  </span>
                </a>
              </div>
            ))}
        </div>
        <div className="brand-list-block-all-brands">
          {brandsArray &&
            brandsArray.map((brand, index) => {
              if (brand[1].length > 1) {
                brand[1].sort((a, b) => (a.brandName > b.brandName ? 1 : -1))
              }
              return (
                <div
                  id={`brand-${brand[0]}`}
                  className="brand-list-row-container"
                  key={index}
                >
                  <span className="brand-list-row-letter">{brand[0]}</span>
                  <div className="brand-list-row-brand-names">
                    {brand[1].map((item) => (
                      <Link href={item.brandLink} key={item.brandName} legacyBehavior>
                        <a>
                          <span className="brand-list-row-brand-name">
                            {item.brandName}
                          </span>
                        </a>
                      </Link>
                    ))}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <style jsx>
        {`
          .brand-list-block {
            margin: 30px 0;
          }
          .brand-list-block-letters {
            display: flex;
            flex-wrap: wrap;
          }
          .brand-list-letter {
            padding-bottom: 30px;
          }
          .brand-list-letter-value {
            text-transform: uppercase;
            background-color: rgb(246, 245, 244);
            color: rgb(70, 69, 68);
            font-size: 13px;
            line-height: 24px;
            font-weight: 700;
            border-radius: 34px;
            padding: 8px 22px;
            margin-right: 10px;
          }
          .brand-list-letter-value:hover {
            background-color: rgb(39, 39, 39);
            color: rgb(245, 245, 245);
            text-decoration: underline;
          }
          .brand-list-block-all-brands {
            margin-top: 20px;
          }
          .brand-list-row-container {
            margin-bottom: 40px;
            padding-top: 120px;
            margin-top: -120px;
          }
          .brand-list-row-letter {
            text-transform: uppercase;
            color: rgb(39, 39, 39);
            font-size: 32px;
            line-height: 40px;
            font-weight: 400;
          }
          .brand-list-row-brand-names {
            display: flex;
            flex-wrap: wrap;
          }
          .brand-list-row-brand-name {
            cursor: pointer;
            font-size: 16px;
            color: rgb(71, 71, 71);
            line-height: 24px;
            font-weight: 400;
            width: 170px;
            display: block;
            padding-top: 24px;
          }
          .brand-list-row-brand-name:hover {
            color: rgb(39, 39, 39);
            text-decoration: underline;
          }
          @media (max-width: 640px) {
            .brand-list-row-brand-name {
              font-size: 13px;
              line-height: 20px;
              width: 120px;
            }
          }
        `}
      </style>
    </div>
  );
}

export default BrandList
