import cn from 'clsx'
import Image from "next/legacy/image"
import React, {
  ButtonHTMLAttributes,
  forwardRef,
  JSXElementConstructor,
  useRef,
} from 'react'
import mergeRefs from 'react-merge-refs'
import LoadingDots from '../LoadingDots'
import s from './Button.module.css'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string
  className?: string
  size?: 'medium' | 'large' | 'small'
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'transparent'
    | 'outlined'
    | 'disabled'
  icon?: String
  active?: boolean
  type?: 'submit' | 'reset' | 'button'
  Component?: string | JSXElementConstructor<any>
  width?: string | number
  loading?: boolean
  disabled?: boolean
  iconName?: any
  iconPosition?: 'start' | 'end'
  testLabel?: string
  showIcon?: boolean
  children?: string
}

const Button: React.FC<ButtonProps> = forwardRef((props, buttonRef) => {
  const {
    className,
    variant = 'primary',
    size = 'medium',
    children,
    active,
    width,
    loading = false,
    disabled = false,
    style = {},
    Component = 'button',
    iconName = null,
    iconPosition = 'start',
    testLabel = '',
    showIcon = iconName,
    ...rest
  } = props
  const ref = useRef<typeof Component>(null)

  const rootClassName = cn(
    s.root,
    {
      [s.primary]: variant === 'primary',
      [s.secondary]: variant === 'secondary',
      [s.tertiary]: variant === 'tertiary',
      [s.transparent]: variant === 'transparent',
      [s.outlined]: variant === 'outlined',
      [s.disabled]: variant === 'disabled',
      [s.small]: size === 'small',
      [s.medium]: size === 'medium',
      [s.large]: size === 'large',
      [s.start]: iconPosition === 'start',
      [s.end]: iconPosition === 'end',
      [s.loading]: loading,
      [s.disabled]: disabled,
    },
    className
  )

  return (
    <Component
      aria-pressed={active}
      data-variant={variant}
      size={size}
      ref={mergeRefs([ref, buttonRef])}
      className={rootClassName}
      disabled={disabled}
      data-testid={`button-${testLabel}`}
      style={{
        width,
        ...style,
      }}
      {...rest}
    >
      {showIcon && iconPosition === 'start' && (
        <Image
          src={iconName}
          width="18"
          height="18"
          alt="startIcon"
          unoptimized
        />
      )}
      {children}
      {showIcon && iconPosition === 'end' && (
        <Image
          src={iconName}
          width="18"
          height="18"
          alt="endIcon"
          unoptimized
        />
      )}
      {loading && (
        <i className="pl-2 m-0 flex">
          <LoadingDots />
        </i>
      )}
    </Component>
  )
})

Button.displayName = 'Button'

export default Button
