import Image from "next/legacy/image"

const ProductInfoListing = ({ slice }) => {
  const { items } = slice

  if (!items.length) return null

  return (
    <div className="mt-5">
      {items.map(
        (item, index) =>
          item.enabled && (
            <div key={index} className="flex items-center mb-2">
              <Image
                src={item?.icon?.url}
                width={18}
                height={14}
                alt={item?.icon?.alt || 'Hoskings'}
                unoptimized
              />
              <div className="text-13 pl-3 text-ui-dark-grey">
                {item?.title}
              </div>
            </div>
          )
      )}
    </div>
  )
}

export default ProductInfoListing
