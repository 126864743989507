export const slugify = (text: string) =>
  text
    .toLocaleLowerCase()
    .trim()
    .replace(/[^\w\- ]+/g, '')
    .replace(/ +/g, '-')
    .replace(/\-\-+/g, '-')

export const getStoreFrontUrl = () => {
  const storeFrontUrl = 'https://www.hoskings.com.au'
  return storeFrontUrl
}

export const capitalizeLetters = (words: string) => {
  return words.replace(/\w\S*/g, (w: string) =>
    w.replace(/^\w/, (c) => c.toUpperCase())
  );
}
