import cn from 'clsx'

interface Props {
  slice: any
}

const Table = ({ slice }: Props) => {
  const title = slice?.primary?.title
  const variation = slice?.variation

  const numberOfColumns = variation === 'threeColumns' ? 3 : 2

  const columnTitles = Array.from(Array(numberOfColumns).keys()).map(
    (i) => slice?.primary[`column${i + 1}Title`]
  )

  const items = slice?.items

  return (
    <section className="py-8 lg:py-16 ">
      {title && (
        <span className="text-mobileh4 lg:text-h4 font-bold">{title}</span>
      )}
      <table
        className={cn(
          'w-full table-auto tablet:table-fixed border-collapse border border-ui-grey-25 mt-6'
        )}
      >
        <thead className="bg-brand-light-grey">
          <tr>
            {columnTitles.map((t, index) => (
              <th
                key={index}
                className={`border border-ui-grey-25 py-2.5 pl-4 text-left bg-ui-lightest-grey body-2-bold tablet:w1/${numberOfColumns}`}
              >
                {t}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items?.map((item: any, index: number) => {
            const columns = Array.from(Array(numberOfColumns).keys()).map(
              (i) => item[`column${i + 1}`]
            )
            return (
              <tr key={index}>
                {columns.map((column, index) => (
                  <td
                    key={index}
                    className="body-2 border border-ui-grey-25 py-2.5 pl-4"
                  >
                    {column}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </section>
  )
}

export default Table
