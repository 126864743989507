import AlgoliaHit from '@components/algolia/AlgoliaHit'
import React, { useState } from 'react'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import CollectionProducts from './CollectionProducts'

interface props {
  title: string
  columns: number
  searchClient: any
  indexName: string
  filterString: string
  defaultCategory: string
}

const FeaturedProducts = ({
  title,
  columns,
  searchClient,
  indexName,
  filterString,
  defaultCategory,
}: props) => {
  const [hitsPerPage] = useState(12)

  if (!indexName) {
    return null
  }

  return (
    <div className="you-might-like">
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        stalledSearchDelay={400}
      >
        <Configure
          hitsPerPage={hitsPerPage}
          filters={filterString}
          ruleContexts={[defaultCategory]}
          distinct
          facetingAfterDistinct
        />
        <div className="algolia_collection__product_list">
          <CollectionProducts
            title={title}
            columns={columns}
            HitComponent={AlgoliaHit}
          />
        </div>
      </InstantSearch>
    </div>
  )
}

export default FeaturedProducts
