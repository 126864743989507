import Link from 'next/link'

// Helper function to convert Prismic Rich Text links to Next/Link components
export const PrismicCustomLink = (
  type: string,
  element: any,
  content: any,
  children: any
) => {
  const url = element?.data?.url
  const href = url?.startsWith('https:///')
    ? url.replace('https:///', '/')
    : url
  return (
    <Link
      key={`${element.type}-${element.start}`}
      href={`${href}`}
      passHref
      target={element?.data?.target || '_self'}>
      {children || content}
    </Link>
  );
}

export default PrismicCustomLink
