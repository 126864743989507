import PrismicCustomLink from '@components/common/PrismicCustomLink'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { FC } from 'react'

interface props {
  content: RichTextBlock[] | undefined
}

const WYSIWYG: FC<props> = ({ content }) => {
  return (
    <div className="wysiwyg">
      <RichText render={content} serializeHyperlink={PrismicCustomLink} />
    </div>
  )
}

export default WYSIWYG
