import WYSIWYG from '@components/ui/WYSIWYG'
import { useState } from 'react'

interface Props {
  slice: any
}

const TabBlock = ({ slice }: Props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const title = slice?.primary?.title
  const content = slice?.primary?.content
  const footer = slice?.primary?.footer
  const items = slice?.items || []
  return (
    <div className="tab-block mb-10">
      {title && (
        <h4 className="font-bold text-body2 text-ui-darkest-grey ">{title}</h4>
      )}
      {content && <p className="mt-6 text-body1">{content}</p>}

      <div className="w-full mt-3">
        {items?.map((item: any, index: number) => {
          if (item.title != null)
            return (
              <button
                key={index}
                className={`rounded-sm px-2 py-0.5 text-body2 transition-colors duration-300 cursor-pointer ${
                  index !== activeTabIndex
                    ? 'text-ui-dark-grey bg-transparent'
                    : 'text-white bg-ui-dark-grey'
                }`}
                // Change the active tab on click.
                onClick={() => setActiveTabIndex(index)}
              >
                {item.title}
              </button>
            )
          else return null
        })}
      </div>
      {items.length > 0 && (
        <div className="w-full content">
          <WYSIWYG content={items[activeTabIndex].content} />
        </div>
      )}

      {footer && (
        <div className="w-full content">
          <WYSIWYG content={footer} />
        </div>
      )}

      <style jsx global>{`
        .content a {
          text-decoration: underline;
        }
        .content ol {
          list-style: auto;
          margin-left: 1.5rem;
          margin-right: 1.5rem;
        }
        .content .wysiwyg > * {
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }
      `}</style>
    </div>
  )
}

export default TabBlock
