import Image from "next/legacy/image"
import { FC } from 'react'

interface Props {
  slice: any
}

interface ItemProps {
  InstagramImage: {
    url: string
    alt: string
  }
  InstagramLink: string
}

interface ItemsProps {
  items: [ItemProps]
}

const InstagramGrid: FC<Props> = ({ slice }) => {
  const { items }: ItemsProps = slice

  return (
    <>
      <div className="desktop:block wrapper mt-8 laptop:mt-12 mb-14 laptop:mb-20">
        <div className="text-text-dark font-normal text-mobileh4 md:text-h4 mb-8 uppercase">
          Follow us @hoskingsjewellers
        </div>
        <div className="grid gap-5 md:gap-x-8 grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
          {items &&
            items.map((item: ItemProps, index: number) => {
              const instagramLink =
                item.InstagramLink ||
                'https://www.instagram.com/hoskingsjewellers'
              return (
                <a
                  href={instagramLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className="block"
                >
                  <div className="cursor-pointer" key={index}>
                    <Image
                      src={item.InstagramImage.url}
                      alt={item.InstagramImage.alt || 'Instagram Image'}
                      width={192}
                      height={192}
                      layout="responsive"
                      unoptimized
                    />
                  </div>
                </a>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default InstagramGrid
