import { FC } from 'react'
import Image from 'next/legacy/image'
import Link from 'next/link'
import ArrowRightSmall from '@components/icons/ArrowRightSmall'

interface Props {
  handle: string
  title: string
  image: any
  excerpt: string | undefined | null
}

const ArticleCard: FC<Props> = ({ handle, title, image, excerpt }) => {
  return (
    <>
      <div className="w-full cursor-pointer">
        <Link href={`/journal/${handle}`} prefetch={false} legacyBehavior>
          <a>
            <div className="articleImageContainer">
              <Image
                src={image?.url || '/product-img-placeholder.svg'}
                alt={title}
                width={340}
                height={175}
                layout="responsive"
                unoptimized
              />
              <div className="overlay"></div>
            </div>
          </a>
        </Link>
        <div className="relative flex flex-col">
          <Link href={`/journal/${handle}`} prefetch={false} legacyBehavior>
            <a>
              <div className="font-normal text-text-dark uppercase text-label mt-3">
                {title}
              </div>
              <p className="font-normal text-text-subdued text-body2 mt-2">
                {excerpt}
              </p>
              <div className="flex font-normal text-text-subdued text-body2 mt-2">
                <span>Read more </span>
                <span className="pl-1.5 pt-1.5">
                  <ArrowRightSmall />
                </span>
              </div>
            </a>
          </Link>
        </div>
      </div>
      <style jsx>
        {`
          .articleImageContainer {
            position: relative;
          }

          .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: 0.5s ease;
            background: #464544;
          }

          .articleImageContainer:hover .overlay {
            opacity: 0.25;
          }
        `}
      </style>
    </>
  )
}

export default ArticleCard
