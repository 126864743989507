import { FC } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import cn from 'clsx'
import Slider from 'react-slick'

interface Props {
  title: string
  columns: number
  hits: []
  HitComponent: any
}

const CollectionProducts: FC<Props> = ({
  title,
  columns,
  hits,
  HitComponent,
}) => {
  const sortedHits = [...hits]
  const theNumberOfColumns: number = Number(columns)

  const sliderSettings = {
    className: cn({ 'hide-slider': sortedHits.length < theNumberOfColumns }),
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: theNumberOfColumns,
    slidesToScroll: theNumberOfColumns,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: cn({ 'hide-slider': sortedHits.length < 4 }),
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          className: cn({ 'hide-slider': sortedHits.length < 3 }),
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          className: cn({ 'hide-slider': sortedHits.length < 2 }),
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  }

  return (
    <div
      className={cn('wrapper my-10 laptop:my-20 product-slick', {
        hidden: sortedHits.length === 0,
      })}
    >
      <div className="uppercase text-text-dark text-mobileh4 md:text-h4">
        {title}
      </div>
      <div className="mt-8">
        <Slider {...sliderSettings}>
          {sortedHits.map((hit: any, index: number) => {
            return <HitComponent key={index} hit={hit} />
          })}
        </Slider>
        <div
          className={cn('gap-4 tablet:gap-6 laptop:gap-8', {
            hidden: sortedHits.length > 4,
            'laptop:flex mobileXS:hidden tablet:hidden':
              sortedHits.length === 3 || sortedHits.length === 4,
            'tablet:flex mobileXS:hidden': sortedHits.length === 2,
            'mobileXS:flex': sortedHits.length === 1,
          })}
        >
          {sortedHits.map((hit: any, index: number) => {
            return (
              <div
                key={hit.id}
                className="w-32 mobileM:w-40 mobileL:w-48 tablet:w-52 desktop:w-56"
              >
                <HitComponent key={index} hit={hit} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default connectHits(CollectionProducts)
