import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import styles from './TextBlock.module.css'

const TextBlock = ({ slice }) => {
  const { container, alignCenter } = slice.primary

  return (
    <div className={`${container && styles.wrapper}`}>
      <div
        className={`${styles.textBlock} ${alignCenter && styles.alignCenter}`}
      >
        <div className={styles.textBlock__heading}>
          {slice.primary.heading && (
            <PrismicRichText field={slice.primary.heading} />
          )}
        </div>

        <div className={styles.textBlock__description}>
          {slice.primary.text && <PrismicRichText field={slice.primary.text} />}
        </div>
      </div>
    </div>
  )
}

export default TextBlock
