import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import styles from './FaqBlock.module.css'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

const FaqBlock = ({ slice }) => {
  const { items } = slice
  const { container, vertical } = slice.primary

  return (
    <div className={`${container && styles.wrapper}`}>
      <div className={vertical ? styles.faqBlockVertical : styles.faqBlock}>
        {slice?.primary?.heading?.length === 0 ? '' : (
          <div className={styles.faqBlockCol}>
            <div className={styles.faqBlock__heading}>
              {slice.primary.heading && (
                <PrismicRichText field={slice.primary.heading} />
              )}
            </div>
            <div className={styles.faqBlock__text}>
              {slice.primary.text && (
                <PrismicRichText field={slice.primary.text} />
              )}
            </div>
          </div>
        )}

        {items && <div className={styles.faqBlockCol}>
          <div className={styles.faqBlock__faqBody}>
            <Accordion allowZeroExpanded>
              {items.map((item, index) => (
                <AccordionItem key={index} className={styles.accItem}>
                  <AccordionItemHeading className={styles.accHeading}>
                    <AccordionItemButton className={styles.accButton}>
                      {item.question[0].text}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className={styles.accPanel}>
                    <PrismicRichText field={item.response} />
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>}
      </div>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": [
                        ${items.map((item) => {
                          let answers = item.response.map((answer) => {
                            return `${answer?.text} `
                          })
                          answers = answers.toString()
                          return ` {
                                    "@type": "Question",
                                    "name": "${item.question[0].text}",
                                    "acceptedAnswer": {
                                      "@type": "Answer",
                                      "text": "${answers}"
                                    }
                                 }`
                        })}
                    ]
                }`,
        }}
      />
    </div>
  )
}

export default FaqBlock
