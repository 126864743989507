import cn from 'clsx'

interface Props {
  slice: any
}

const SizeChartTable = ({ slice }: Props) => {
  const title1 = slice?.primary?.title1 || 'Column Title 1'
  const title2 = slice?.primary?.title2 || 'Column Title 2'
  const title3 = slice?.primary?.title3 || 'Column Title 3'
  const items = slice?.items || [
    {
      content1: 'Column Content 1',
      content2: 'Column Content 2',
      content3: 'Column Content 3',
    },
  ]
  return (
    <table
      className={cn(
        'w-full table-auto tablet:table-fixed border-collapse border border-ui-grey-25 text-body2 mb-10'
      )}
    >
      <thead>
        <tr>
          <th
            className={cn(
              'border border-ui-grey-25 p-2 text-left bg-white body-2-bold align-top'
            )}
          >
            {title1}
          </th>
          <th
            className={cn(
              'border border-ui-grey-25 p-2 text-left bg-white body-2-bold align-top'
            )}
          >
            {title2}
          </th>
          <th
            className={cn(
              'border border-ui-grey-25 p-2 text-left bg-white body-2-bold align-top'
            )}
          >
            {title3}
          </th>
        </tr>
      </thead>
      <tbody>
        {items?.map((item: any, index: number) => {
          return (
            <tr
              key={index}
              className={index % 2 === 0 ? 'bg-white/50' : 'bg-white'}
            >
              <td className="body-2 border border-ui-grey-25 p-2">
                {item?.content1}
              </td>
              <td className="body-2 border border-ui-grey-25 p-2">
                {item?.content2}
              </td>
              <td className="body-2 border border-ui-grey-25 p-2">
                {item?.content3}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
export default SizeChartTable
