import React from 'react'

const Iframe = ({ slice }) => (
  <section className="mt-16">
    <iframe
      src={slice?.primary?.source}
      width={slice?.primary?.width}
      height={slice?.primary?.height}
    />
  </section>
)

export default Iframe
