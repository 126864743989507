import React, { useState } from 'react'
import { PrismicRichText } from '@prismicio/react'
import styles from './ReadMoreBlock.module.css'
import Image from "next/legacy/image"
import minusIcon from '../../../site/public/icons/minusIcon.svg'
import plusIcon from '../../../site/public/icons/plusIcon.svg'
import clsx from 'clsx'

const ReadMoreBlock = ({ slice }) => {
  const [showMoreText, setShowMoreText] = useState(false)
  const { container, headingTag, contentTextColor } = slice.primary

  const readMoreBtnHandler = () => {
    setShowMoreText(!showMoreText)
  }

  return (
    <div className={clsx(container && styles.wrapper)}>
      <div className={styles.readMoreBlock}>
        <div className={styles.heading}>
          {headingTag == 'h1' && <h1>{slice.primary.heading}</h1>}
          {headingTag == 'h2' && <h2>{slice.primary.heading}</h2>}
        </div>
        <div
          className={clsx(
            styles.text,
            !showMoreText && styles.addDots,
            contentTextColor ? `text-[${contentTextColor}]` : `text-[#272727]`
          )}
        >
          <PrismicRichText field={slice.primary.text} />
        </div>

        <div
          className={clsx(
            styles.moreText,
            contentTextColor ? `text-[${contentTextColor}]` : `text-[#272727]`
          )}
        >
          {showMoreText && (
            <PrismicRichText field={slice.primary.readMoreText} />
          )}
        </div>

        <div className={styles.readMoreBtn}>
          <button
            className={clsx(
              styles.btn,
              contentTextColor ? `text-[${contentTextColor}]` : `text-[#272727]`
            )}
            onClick={() => readMoreBtnHandler()}
          >
            {showMoreText ? (
              <span className={styles.btnText}>Read less</span>
            ) : (
              <span className={styles.btnText}>Read more</span>
            )}
            {showMoreText ? (
              <Image
                className={styles.minusIcon}
                src={minusIcon}
                width={13}
                height={13}
                unoptimized
              />
            ) : (
              <Image src={plusIcon} width={13} height={13} unoptimized />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ReadMoreBlock
