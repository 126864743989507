import React from 'react'
import Link from 'next/link'

const LinkContainer: React.FC<{
  linkURL: string
  title: string
  children: JSX.Element
  href: HTMLLinkElement
}> = ({ linkURL, children }) => {
  return (
    <Link href={linkURL}>
      {children}
    </Link>
  );
}

export default LinkContainer
