import SearchClient, { getDefaultSearchString } from '@lib/algolia'
import FeaturedProducts from '@components/product/FeaturedProducts'

interface Props {
  slice: any
}

const CollectionProductsGrid = ({ slice }: Props) => {
  const { title, columns, shopifyCollectionHandle } = slice.primary

  const collectionHandle = shopifyCollectionHandle as string
  const algoliaIndex = process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_PRIMARY_INDEX!
  const defaultFilterString = getDefaultSearchString(
    `collections:${collectionHandle}`
  )

  return (
    <FeaturedProducts
      title={title}
      columns={columns || 4}
      searchClient={SearchClient}
      indexName={algoliaIndex}
      defaultCategory={shopifyCollectionHandle}
      filterString={defaultFilterString}
    />
  )
}

export default CollectionProductsGrid
