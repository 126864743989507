import ArticlesGrid from '@components/blog/ArticlesGrid'

const BlogBlock = ({ slice, context }) => {
  const { title } = slice?.primary
  const { articles } = context

  if (!articles) return null

  return <ArticlesGrid title={title || 'Blog Article'} articles={articles} />
}

export default BlogBlock
