import React, { useState, useEffect } from 'react'
import Image from "next/legacy/image"
import Link from 'next/link'
import { PrismicRichText } from '@prismicio/react'
import Slider from 'react-slick'
import styles from './Grid.module.css'
import clsx from 'clsx'

const Grid = ({ slice }) => {
  const [girdItems, setGridItems] = useState([])
  const [isShowMoreClicked, setIsShowMoreClicked] = useState(false)

  const { container, columns, showMoreButton, tilesPerPage, layoutOption } =
    slice.primary

  const settings = {
    infinite: true,
    // autoplay: true,
    speed: 500,
    slidesToShow: tilesPerPage,
    slidesToScroll: tilesPerPage,
    // autoplaySpeed: 5000,
    dots: true,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
    ],
  }

  useEffect(() => {
    // for grid layout if show more is enabled and
    // tailsPerPage is less than the length of slice items
    if (
      tilesPerPage < slice.items.length &&
      layoutOption == 'grid' &&
      showMoreButton
    ) {
      setGridItems(slice.items.slice(0, tilesPerPage))
    } else {
      // for carousel, no need to slice
      setGridItems(slice.items)
    }
  }, [])

  const showMoreHandler = () => {
    setGridItems(slice.items)
    setIsShowMoreClicked(true)
  }

  return (
    <div className={`${container && styles.wrapper}`}>
      <div className={`${styles.grid} grid-slick`}>
        <div className={styles.grid__heading}>
          {slice.primary.heading && (
            <PrismicRichText field={slice.primary.heading} />
          )}
        </div>
        {/* default layout is carousel */}
        {layoutOption === 'carousel' || null ? (
          <Slider {...settings}>
            {girdItems.map((item, i) => {
              const ImageWidth = item?.image?.dimensions?.width
              const ImageHeight = item?.image?.dimensions?.height
              return (
                <Link href={item.link ? item.link : '#'} key={i} legacyBehavior>
                  <div className={styles.grid__item} key={i}>
                    {item.image.url && (
                      <Image
                        src={item.image.url}
                        alt={item.image.alt || slice.primary.heading[0].text}
                        width={ImageWidth}
                        height={ImageHeight}
                        unoptimized
                      />
                    )}

                    <div className={styles.grid__itemTitle}>
                      {item.title && <PrismicRichText field={item.title} />}
                    </div>
                  </div>
                </Link>
              );
            })}
          </Slider>
        ) : (
          <div
            className={clsx(
              styles.grid__body,
              (columns === '4' || columns == null) && styles.grid__4Cols,
              columns === '6' && styles.grid__6Cols
            )}
          >
            {girdItems.map((item, i) => {
              return (
                <Link href={item.link ? item.link : '#'} key={i} legacyBehavior>
                  <div className={styles.grid__item}>
                    {item.image.url && (
                      <Image
                        src={item.image.url}
                        alt={item.image.alt || slice.primary.heading[0].text}
                        width={item?.image?.dimensions?.width}
                        height={item?.image?.dimensions?.height}
                        unoptimized
                      />
                    )}

                    <div className={styles.grid__itemTitle}>
                      {item.title && <PrismicRichText field={item.title} />}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}

        {!isShowMoreClicked &&
          showMoreButton &&
          tilesPerPage < slice.items.length && (
            <button
              className={styles.showMoreBtn}
              onClick={() => showMoreHandler()}
            >
              Show more
            </button>
          )}
      </div>
    </div>
  );
}

export default Grid
