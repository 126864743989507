import React from 'react'
import { PrismicRichText, PrismicText } from '@prismicio/react'
import styles from './TextTable.module.css'
import clsx from 'clsx'

const TextTable = ({ slice }) => {
  const rootClass = clsx(styles.root, {
    [styles.wrapper]: slice.primary.container,
  })
  return (
    <div className={rootClass}>
      <div className={styles.textTable}>
        <div className={styles.textTable__heading}>
          {slice.primary.heading && (
            <PrismicRichText field={slice.primary.heading} />
          )}
        </div>
        <div className={styles.textTable__body}>
          {slice?.items?.map((item, index) => (
            <div className={styles.textTable__row} key={index}>
              <div className={styles.textTable__head}>
                <PrismicText field={item.tableHead} />
              </div>
              <div className={styles.textTable__data}>
                <PrismicText field={item.tableData} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TextTable
