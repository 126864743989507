import WYSIWYG from '@components/ui/WYSIWYG'
import { slugify } from '@lib/helpers/general'
import cn from 'clsx'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  resetNextUuid,
} from 'react-accessible-accordion'
interface Props {
  slice: any
}

const Faq = ({ slice }: Props) => {
  resetNextUuid();
  return (
    <div className={cn('mb-6 faqs')}>
      {slice?.primary?.title && (
        <div className="text-mobileh4 lg:text-h4 text-dark-grey pt-16 lg:pb-4 font-bold">
          {slice?.primary?.title}
        </div>
      )}
      <Accordion allowMultipleExpanded allowZeroExpanded className="">
        {slice.items.map((item: any, key: number) => {
          if (!item?.question) return <></>
          return (
            <AccordionItem
              uuid={`${slugify(item?.question)}_${key}`}
              key={`${slugify(item?.question)}_${key}`}
              className="accordion-item border-b border-greyBg"
            >
              <AccordionItemHeading>
                <AccordionItemButton className="flex items-center accordion__button">
                  <h2 className="text-body1 text-dark-grey font-light">
                    {item?.question}
                  </h2>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordion-panel text-body1 text-dark-grey font-light pb-4">
                <WYSIWYG content={item?.answer} />
              </AccordionItemPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              ${slice.items.map((item: any, index: number) => {
                return ` {
                  "@type": "Question",
                  "name": "${item?.question}",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "${(
                      <WYSIWYG content={item?.answer} />
                    )}"
                  }
                 }`
              })}
            ]
        }`,
        }}
      />
      <style jsx global>
        {`
          .faqs .accordion__button {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            text-align: left;
            padding: 1.5rem 1.25rem 1.5rem 0;
            transition: color 0.2s linear;
            cursor: pointer;
            text-transform: capitalize;
            color: rgb(0, 0, 0);
          }

          .faqs .accordion__button:focus {
            outline: none;
          }

          .faqs .accordion__button:after,
          .faqs .accordion__button:before {
            content: '';
            position: absolute;
            right: 0px;
            top: 50%;
            width: 16px;
            height: 1px;
            margin-top: -2px;
            background-color: #afb1b4;
          }

          .faqs .accordion__button:before {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
            transition: -webkit-transform 0.35s
              cubic-bezier(0.65, 0.05, 0.36, 1);
            transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1),
              -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
          }

          .faqs .accordion__button[aria-expanded='true']:before {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
        `}
      </style>
    </div>
  )
}

export default Faq
