import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { RichTextField } from '@prismicio/types'
import styles from './HeadingText.module.css'
import classnames from 'classnames'

import Button from '@components/ui/HoskingsButton/Button'
import { content } from 'tailwind.config'

const HeadingText: React.FC<{
  heading: RichTextField
  text: RichTextField
  headingType: string
  layoutType: string
  CTA: string
  CTAText: string
  contentSpacing: string
}> = ({
  heading,
  text,
  headingType,
  layoutType,
  CTA,
  CTAText,
  contentSpacing,
}) => {
  const containerClass = classnames(
    {
      [styles.headingLeftBetween]:
        layoutType === 'left' && contentSpacing === 'space between',
    },
    {
      [styles.headingCentreBetween]:
        layoutType !== 'left' && contentSpacing === 'space between',
    },
    {
      [styles.headingLeft]:
        layoutType === 'left' && contentSpacing !== 'space between',
    },
    {
      [styles.headingCentre]:
        layoutType !== 'left' && contentSpacing !== 'space between',
    }
  )
  const contentClass = classnames(
    {
      [styles.contentLeftBetween]:
        layoutType === 'left' && contentSpacing === 'space between',
    },
    {
      [styles.contentCentreBetween]:
        layoutType !== 'left' && contentSpacing === 'space between',
    },
    {
      [styles.contentLeft]:
        layoutType === 'left' && contentSpacing !== 'space between',
    },
    {
      [styles.contentCentre]:
        layoutType !== 'left' && contentSpacing !== 'space between',
    }
  )

  const buttonClass = classnames(
    {
      [styles.buttonLeftBetween]:
        layoutType === 'left' && contentSpacing === 'space between',
    },
    {
      [styles.buttonCentreBetween]:
        layoutType !== 'left' && contentSpacing === 'space between',
    },
    {
      [styles.buttonLeft]:
        layoutType === 'left' && contentSpacing !== 'space between',
    },
    {
      [styles.buttonCentre]:
        layoutType !== 'left' && contentSpacing !== 'space between',
    }
  )

  return (
    <>
      <div className={containerClass}>
        {headingType === 'H1' ? (
          <span className={styles.heading}>
            <h1>
              <PrismicRichText field={heading} />
            </h1>
          </span>
        ) : (
          <span className={styles.heading}>
            <h2>
              <PrismicRichText field={heading} />
            </h2>
          </span>
        )}
        {CTA === 'Link' ? (
          <p className={contentClass}>
            <PrismicRichText field={text} />
          </p>
        ) : (
          <div className={buttonClass}>
            {contentClass.includes('contentCentre') ? (
              <Button className={styles.buttonCentre} variant="transparent">
                {CTAText}
              </Button>
            ) : (
              <Button variant="transparent">{CTAText}</Button>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default HeadingText
