import Image from "next/legacy/image"

const ServiceBlock = ({ slice }) => {
  const { items } = slice

  return (
    <>
      <div className="wrapper my-0 blockContainer tablet:my-20">
        <div className="block tablet:mt-0 mt-1.5 tablet:flex">
          {items &&
            items.map((item) => {
              const ImageUrl = item?.serviceImage?.url
              const ImageWidth = item?.serviceImage?.dimensions?.width
              const ImageHeight = item?.serviceImage?.dimensions?.height
              const title = item?.serviceTitle
              const text = item?.serviceText
              return (
                <div
                  className="p-4 flex-1 tablet:py-6"
                  key={item?.serviceTitle}
                >
                  {ImageUrl && (
                    <div className="flex justify-center items-center mb-4 imageContainer">
                      <Image
                        src={ImageUrl}
                        alt={title}
                        width={ImageWidth}
                        height={ImageHeight}
                        unoptimized
                      />
                    </div>
                  )}
                  {title && (
                    <div className="text-center text-13 leading-6 font-bold">
                      {title}
                    </div>
                  )}
                  {text && (
                    <div className="text-center pt-2 text-13 leading-6 font-normal">
                      {text}
                    </div>
                  )}
                </div>
              )
            })}
        </div>

        <style jsx>
          {`
            @media screen and (max-width: 758px) {
              .blockContainer {
                margin-left: 0px !important;
                margin-right: 0px !important;
                padding: 0px !important;
              }
            }
            .imageContainer {
              min-height: 32px !important;
            }
          `}
        </style>
      </div>
    </>
  )
}

export default ServiceBlock
