import React from 'react'

const Code = ({ slice }) => {
  const code = slice.primary.code[0]?.text

  return (
    <section>
      {code && <div dangerouslySetInnerHTML={{ __html: code }} />}
    </section>
  )
}

export default Code
