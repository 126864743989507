import React from 'react'
import styles from './HeroBlockSlice.module.css'
import LinkContainer from '@components/common/LinkContainer/LinkContainer'
import HeadingText from '@components/common/HeadingText'
import Image from "next/legacy/image"

const HeroBlockSlice = ({ slice }) => {
  const {
    desktopImage,
    mobileImage,
    heading,
    subheading,
    fontColour,
    layoutType,
    contentSpacing,
    headingType,
    CTA,
    linkURL,
    CTAText,
  } = slice.primary

  return (
    <span style={{ color: fontColour }}>
      <div className={styles.imageContainer}>
        <LinkContainer linkURL={linkURL.url || '#'} title={heading}>
          <div className={styles.imageDesktop}>
            <Image
              src={desktopImage.url}
              width="1312px"
              height="560px"
              layout="responsive"
              unoptimized
            ></Image>
            <HeadingText
              heading={heading}
              text={subheading}
              headingType={headingType}
              layoutType={layoutType}
              CTA={CTA}
              CTAText={CTAText}
              contentSpacing={contentSpacing}
            />
          </div>
          <div className={styles.imageMobile}>
            <Image
              src={mobileImage.url}
              width="336px"
              height="336px"
              layout="responsive"
              unoptimized
            ></Image>
            <HeadingText
              heading={heading}
              text={subheading}
              headingType={headingType}
              layoutType={layoutType}
              CTA={CTA}
              CTAText={CTAText}
              contentSpacing={contentSpacing}
            />
          </div>
        </LinkContainer>
      </div>
    </span>
  )
}

export default HeroBlockSlice
