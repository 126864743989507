import React, { useState, useEffect } from 'react'
import Image from "next/legacy/image"
import Link from 'next/link'
import { PrismicRichText } from '@prismicio/react'
import Slider from 'react-slick'
import styles from './HomepageCollectionsGrid.module.css'

const HomepageCollectionsGrid = ({ slice }) => {
  const [girdItems, setGridItems] = useState([])
  const [isShowMoreClicked, setIsShowMoreClicked] = useState(false)

  const { container, columns, showMoreButton, tilesPerPage, mobileGridLayout } =
    slice.primary

  const settings = {
    infinite: true,
    // autoplay: true,
    speed: 500,
    slidesToShow: tilesPerPage,
    slidesToScroll: 1,
    // autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: tilesPerPage,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }

  useEffect(() => {
    if (tilesPerPage) {
      setGridItems(slice.items.slice(0, tilesPerPage))
    }
  }, [])

  const showMoreHandler = () => {
    setGridItems(slice.items)
    setIsShowMoreClicked(true)
  }

  return (
    <div className={`${(container && styles.wrapper) || styles.wrapper}`}>
      <div className={styles.homeCollectionsRow}>
        <div className={styles.grid__heading}>
          {slice.primary.heading && (
            <PrismicRichText field={slice.primary.heading} />
          )}
        </div>
        <div className={`${styles.grid} grid-slick`}>
          {/* <div className={styles.grid__body}> */}
          {mobileGridLayout === 'carousel' ? (
            <Slider {...settings}>
              {girdItems.map((item, i) => {
                const ImageWidth = item?.image?.dimensions?.width
                const ImageHeight = item?.image?.dimensions?.height
                return (
                  <Link href={item.link ? item.link : '#'} key={i} legacyBehavior>
                    <div className={styles.grid__item} key={i}>
                      <Image
                        src={item.image.url}
                        alt={item.image.alt || slice.primary.heading[0].text}
                        width={ImageWidth}
                        height={ImageHeight}
                        unoptimized
                      />
                      <div className={styles.grid__itemTitle}>
                        {item.title && <PrismicRichText field={item.title} />}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Slider>
          ) : (
            <div
              className={`${styles.grid__body} ${
                (columns === '3' || columns == null) && styles.grid__3Cols
              } ${columns === '4' && styles.grid__4Cols}`}
            >
              {girdItems.map((item, i) => {
                const ImageWidth = item?.image?.dimensions?.width
                const ImageHeight = item?.image?.dimensions?.height
                return (
                  <Link href={item.link ? item.link : '#'} key={i} legacyBehavior>
                    <div className={styles.grid__item}>
                      <Image
                        src={item.image.url}
                        alt={item.image.alt || slice.primary.heading[0].text}
                        width={ImageWidth}
                        height={ImageHeight}
                        unoptimized
                      />
                      <div className={styles.grid__itemTitle}>
                        {item.title && <PrismicRichText field={item.title} />}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          )}

          {!isShowMoreClicked && showMoreButton && (
            <button
              className={styles.showMoreBtn}
              onClick={() => showMoreHandler()}
            >
              Show more
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomepageCollectionsGrid
