import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import styles from './DownloadBlock.module.css'

const DownloadBlock = ({ slice }) => {
  const { items } = slice
  const { container } = slice.primary

  return (
    <div className={`${container && styles.wrapper}`}>
      <div className={styles.downloadBlock}>
        <div className={styles.downloadBlock__heading}>
          {slice.primary.heading && (
            <PrismicRichText field={slice.primary.heading} />
          )}
        </div>

        {items.map((item, index) => {
          return (
            <a
              className={styles.downloadBlock__link}
              key={index}
              target="_blank"
              href={item.url.url}
            >
              <img src={item.icon.url} alt="" />
              <p className="">{item.description[0].text}</p>
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default DownloadBlock
